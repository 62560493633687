const serverUrl = "/api/"

const loginUrl = serverUrl + "login/"
const logoutUrl = serverUrl + "logout/"
const registerUrl = serverUrl + "register/"
const registerAdminUrl = serverUrl + "register/admin/"
const userInfosUrl= serverUrl + "utilisateur/infos/"
const resetPasswordDemandUrl = serverUrl + "utilisateur/reset_password/demand/"
const resetPasswordActionUrl = serverUrl + "utilisateur/reset_password/action/"
const resetPasswordCheckLinkUrl = serverUrl + "utilisateur/reset_password/check_link/"
const userProjetsUrl = serverUrl + "utilisateur/{id}/projets/"
const userUpdatePasswordUrl = serverUrl + "utilisateur/{id}/update_password/"
const projetsUrl = serverUrl + "projets/"
const projetOrdreUrl = serverUrl + "projets/ordre/"
const projetUrl = serverUrl + "projet/{id}/"
const projetUnassignUrl = serverUrl + "projet/{id}/unassign/"
const projetObjectifsUrl = serverUrl + "projet/{id}/objectifs/"
const projetActivitesUrl = serverUrl + "projet/{id}/activites/"
const projetTachesUrl = serverUrl + "projet/{id}/taches/"
const projetUsersUrl = serverUrl + "projet/{id}/utilisateurs/"
const planActionExcelUrl = serverUrl + "projet/{id}/rapport/excel/"
const rapportInternePdfUrl = serverUrl + "projet/{id}/rapport/pdf/interne/"
const rapportExternePdfUrl = serverUrl + "projet/{id}/rapport/pdf/externe/"
const assignationsUrl = serverUrl + "assignations/"
const rappelsUrl = serverUrl + "projet/{id}/rappels/"
const rappelUrl = serverUrl + "rappel/{id}/"
const objectifsUrl = serverUrl + "projet/{id}/objectifs/"
const objectifsOrdreUrl = serverUrl + "objectifs/ordre/"
const objectifUrl = serverUrl + "objectif/{id}/"
const activitesOrdreUrl = serverUrl + "activites/ordre/"
const activitesUrl = serverUrl + "activites/"
const activiteUrl = serverUrl + "activite/{id}/"
const tachesUrl = serverUrl + "taches/"
const tachesOrdreUrl = serverUrl + "taches/ordre/"
const tacheUrl = serverUrl + "tache/{id}/"
const rapportUrl = serverUrl + "rapport/{id}/"
const salonsUrl = serverUrl + "projet/{id}/salons/"
const salonUrl = serverUrl + "salon/{id}/"
const usersUrl = serverUrl + "utilisateurs/"
const userUrl = serverUrl + "utilisateur/{id}/"
const userWithoutProjectUrl = serverUrl + "utilisateurs/noproject/"
const userAssignationsUrl = serverUrl + "utilisateur/{id}/assignations/"
const commentairesUrl = serverUrl + "projet/{id}/rapport/commentaires/{type}/"
const commentaireUrl = serverUrl + "commentaire/{id}/"
const convertTacheToActiviteUrl = serverUrl + "convert/tache/{id}/activite/"
const convertActiviteToTacheUrl = serverUrl + "convert/activite/{id}/tache/"


export { userInfosUrl,logoutUrl,objectifsOrdreUrl,tachesOrdreUrl, activitesOrdreUrl, projetOrdreUrl, rappelUrl, rappelsUrl, assignationsUrl, usersUrl, userUpdatePasswordUrl, userAssignationsUrl, userWithoutProjectUrl, userUrl, loginUrl, registerUrl, registerAdminUrl, resetPasswordDemandUrl, resetPasswordActionUrl, resetPasswordCheckLinkUrl, tachesUrl, rapportUrl, tacheUrl, objectifsUrl, objectifUrl, activitesUrl, activiteUrl, projetsUrl, projetUnassignUrl, projetUsersUrl, userProjetsUrl, projetUrl, salonsUrl, salonUrl, commentairesUrl, commentaireUrl, planActionExcelUrl, rapportInternePdfUrl, rapportExternePdfUrl, convertActiviteToTacheUrl, convertTacheToActiviteUrl, projetObjectifsUrl, projetActivitesUrl, projetTachesUrl }