<template>
  <div v-if="dataCalculated">
    <RapportExterne v-if="externe" :projet_id="projet_id" :sections="sections"
      :dataEcheanciersProjet="dataEcheanciersProjet" :stackedBarDataEcheanciersPerObjectif="
        stackedBarDataEcheanciersPerObjectif
      " :commentaires="commentaires" :salons_headers="salons_headers" :objectifs="objectifs"></RapportExterne>
    <RapportInterne v-if="interne" :projet_id="projet_id" :sections="sections"
      :dataEcheanciersProjet="dataEcheanciersProjet" :dataAvancementProjet="dataAvancementProjet"
      :stackedBarDataEcheanciersPerObjectif="
        stackedBarDataEcheanciersPerObjectif
      " :stackedBarDataAvancementPerObjectif="stackedBarDataAvancementPerObjectif" :commentaires="commentaires"
      :salons_headers="salons_headers" :objectifs="objectifs"></RapportInterne>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RapportInterne from "./RapportInterne.vue";
import RapportExterne from "./RapportExterne.vue";
export default {
  name: "RapportViewComponent",
  components: {
    RapportInterne,
    RapportExterne,
  },
  props: ["projet_id", "type_rapport"],
  data: () => ({
    projet: null,
    objectifs: null,
    activites: null,
    taches: null,
    commentaires: null,
    dataEcheanciersProjet: null,
    dataAvancementProjet: null,
    optionsEcheanciersActiviteProjet: null,
    seriesEcheanciersActiviteProjet: null,
    stackedBarDataEcheanciersPerObjectif: [],
    stackedBarDataAvancementPerObjectif: [],
    dataCalculated: false,
    interne: false,
    externe: false,
    sections: ["Ensemble du projet", "Salons", "Donneées par objectif"],
    avancementItems: [
      { label: "Non débuté", color: "#FF0000" },
      { label: "En continu", color: "#C5E0B4" },
      { label: "En cours", color: "#FFC000" },
      { label: "Terminé", color: "#548235" },
    ],
    echeanciersItems: [
      { label: "Respecté", color: "#C5E0B4" },
      { label: "Devancé", color: "#548235" },
      { label: "Retard", color: "#FF0000" },
    ],
    salons_headers: [
      {
        text: "",snackbar:false,
        value: "nom_salon",
        show: true,
      },
      {
        text: "Type de participation",
        value: "type_participation",
        show: true,
      },
      { text: "Réservation effectuée", value: "reservation", show: true },
      { text: "Avancement", value: "avancement", show: true },
      { text: "RESPONSABLE", value: "responsable", show: false },
      { text: "AVEC L'APPUI DE", value: "appui", show: false },
      { text: "Commentaires", value: "commentaires", show: false },
      { text: "Controles", value: "actions", sortable: false, show: false },
    ],
  }),
  computed: {
    dataFetched() {
      return this.projet != null && this.commentaires != null && this.objectifs != null && this.activites != null && this.taches != null
    },
    activitesByObjectif() {
      return this.activites?.reduce((accumulator, activite) => {
        let objectif_id = activite.objectif
        if (Object.keys(accumulator).includes(objectif_id.toString()))
          accumulator[objectif_id].push(activite)
        else
          accumulator[objectif_id] = [activite]
        return accumulator
      }, {})
    },
    tachesByActivite() {
      return this.taches?.reduce((accumulator, tache) => {
        let activite_id = tache.activite
        if (Object.keys(accumulator).includes(activite_id.toString()))
          accumulator[activite_id].push(tache)
        else
          accumulator[activite_id] = [tache]
        return accumulator
      }, {})
    }
  },
  methods: {
    ...mapActions(["fetchProjet", "fetchObjectifsProjet", "fetchActivitesProjet", "fetchTachesProjet", "fetchCommentaires"]),
    setVizualisations() {
      this.dataEcheanciersProjet = this.proportionsEcheanciersEnsembleProjet();

      this.dataAvancementProjet =
        this.proportionsAvancementActivitesEnsembleProjet();

      this.stackedBarDataEcheanciersPerObjectif =
        this.proportionsEcheanciersActivitesParObjectifStatégiques();

      this.stackedBarDataAvancementPerObjectif =
        this.proportionsAvancementActivitesParObjectifStatégiques();

      this.dataCalculated = true;
    },

    proportionsEcheanciersActivitesParObjectifStatégiques() {
      var proportionsEcheancier = this.objectifs.map((objectif) => {
        var activites = this.activitesByObjectif[objectif.id];
        var categories = [];
        var series = [];
        var colors = [];
        activites.map((activite, activite_index) => {
          categories.push(activite.nom_activite);
          var taches = this.tachesByActivite[activite.id];
          this.echeanciersItems.map((item) => {
            var index = series.findIndex((s) => s.name == item.label);
            var nb_occurences = taches.reduce((accumulator, tache) => {
              if (tache.etat_echeancier == item.label) {
                accumulator += 1;
              }
              return accumulator;
            }, 0);
            if (index == -1) {
              series.push({ name: item.label, data: [nb_occurences] });
            } else {
              if (series[index].data[activite_index] == undefined) {
                series[index].data.push(nb_occurences);
              } else {
                series[index].data[activite_index] += nb_occurences;
              }
            }
            colors.push(item.color);
          });
        });
        var options = {
          colors: colors,
          chart: {
            type: "bar",
            stacked: "true",
            stackType: "100%",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                hideOverflowingLabels: true,
              },
            },
          },
          title: { text: "Echéanciers " + objectif.nom_objectif },
          xaxis: { categories: categories },
        };
        return {
          series: series,
          options: options,
        };
      });
      return proportionsEcheancier;
    },

    proportionsAvancementActivitesParObjectifStatégiques() {
      var proportionsAvancement = this.objectifs.map((objectif) => {
        var activites = this.activitesByObjectif[objectif.id];
        var categories = [];
        var series = [];
        var colors = [];
        activites.map((activite, activite_index) => {
          categories.push(activite.nom_activite);
          var taches = this.tachesByActivite[activite.id];
          this.avancementItems.map((item) => {
            var index = series.findIndex((s) => s.name == item.label);
            var nb_occurences = taches.reduce((accumulator, tache) => {
              if (tache.avancement == item.label) {
                accumulator += 1;
              }
              return accumulator;
            }, 0);
            if (index == -1) {
              series.push({ name: item.label, data: [nb_occurences] });
            } else {
              if (series[index].data[activite_index] == undefined) {
                series[index].data.push(nb_occurences);
              } else {
                series[index].data[activite_index] += nb_occurences;
              }
            }
            colors.push(item.color);
          });
        });
        var options = {
          colors: colors,
          chart: {
            type: "bar",
            stacked: "true",
            stackType: "100%",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                hideOverflowingLabels: true,
              },
            },
          },
          title: { text: "Avancement " + objectif.nom_objectif },
          xaxis: { categories: categories },
        };
        return {
          series: series,
          options: options,
        };
      });
      return proportionsAvancement;
    },

    proportionsAvancementActivitesEnsembleProjet() {
      var proportionsAvancement = this.objectifs.reduce(
        (accumulator, objectif) => {
          var activites = this.activitesByObjectif[objectif.id];

          activites.map((activite) => {
            var taches = this.tachesByActivite[activite.id];
            taches.map((tache) => {
              var avancement = tache.avancement;
              if (avancement in accumulator) accumulator[avancement] += 1;
              else accumulator[avancement] = 1;
            });
          });
          return accumulator;
        },
        {}
      );
      var labels = Object.keys(proportionsAvancement);
      var colors = labels.map((label) => {
        var index = this.avancementItems.findIndex(
          (item) => item.label == label
        );
        return this.avancementItems[index].color;
      });
      var options = {
        labels: labels,
        colors: colors,
        title: { text: "Avancement des activités:Ensemble du projet" },
      };
      var series = Object.values(proportionsAvancement);
      return { options, series };
    },

    proportionsEcheanciersEnsembleProjet() {
      var proportionsEcheancier = this.objectifs.reduce(
        (accumulator, objectif) => {
          var activites = this.activitesByObjectif[objectif.id];
          activites.map((activite) => {
            var taches = this.tachesByActivite[activite.id];
            taches.map((tache) => {
              var etat_echeancier = tache.etat_echeancier;
              if (etat_echeancier in accumulator)
                accumulator[etat_echeancier] += 1;
              else {
                accumulator[etat_echeancier] = 1;
              }
            });
          });
          return accumulator;
        },
        {}
      );
      var labels = Object.keys(proportionsEcheancier);
      var colors = labels.map((label) => {
        var index = this.echeanciersItems.findIndex(
          (item) => item.label == label
        );
        return this.echeanciersItems[index].color;
      });
      var options = {
        labels: labels,
        colors: colors,
        title: { text: "Echéanciers:Ensemble du projet" },
      };
      var series = Object.values(proportionsEcheancier);
      return { options, series };
    },

    async fetchData() {
      this.fetchProjet(this.projet_id).then((p) => {
        this.projet = p;
      });

      this.fetchObjectifsProjet(this.projet_id).then((o) => {
        this.objectifs = o;
      });

      this.fetchActivitesProjet(this.projet_id).then((a) => {
        this.activites = a;
      });

      this.fetchTachesProjet(this.projet_id).then((t) => {
        this.taches = t;
      });

      var data = {
        projet_id: this.projet_id,
        type_rapport: this.type_rapport,
      };
      this.fetchCommentaires(data).then((commentaires) => {
        this.commentaires = commentaires;
      })
      
      return 
    },

  },
  created() {

    if (this.type_rapport == "interne") {
      this.interne = true;
    } else if (this.type_rapport == "externe") {
      this.externe = true;
    } else {
      this.$router.push({
        name: "404",
      });
    }

    this.fetchData()
  },

  watch:{
    dataFetched(newValue)
    {
      if(newValue)
      {
        this.setVizualisations()
      }
    }
  }
};
</script>


<style>
.piecharts {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.histograms {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.histograms .chart {
  margin: 25px auto;
}
</style>